


























import {Vue, Component} from "vue-property-decorator";
import FriendExamineType, {AddressListType} from "@/views/Friend/FriendExamine/FriendExamine";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import {data} from "@/views/Friend/FriendExamine/Model/index";
import {IssueFriendStore} from "@/views/Friend/IssueFriend/Store";
import ZoomPage from "@/impView/PageSafety";

@Component({name: "FriendExamine",components:{ HeadTop }})
export default class FriendExamine extends ZoomPage implements FriendExamineType{
    activeIcon = require("../Img/FirendActive.png")
    addressList:AddressListType[] = data
    selIndex = 0
    loadingShow = true

    mounted(){
        this.selIndex = this.addressList.findIndex(a=>a.type == IssueFriendStore.getIssueAdmin.type) || 0
        this.loadingShow = false
    }

    handleShowClick(index: number): void {
        let data = this.addressList[index]
        IssueFriendStore.setIssueAdmin({ value:data.content,type:data.type })
        this.$router.go(-1)
    }

}
